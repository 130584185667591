import React, { FC, MouseEventHandler, useState } from 'react'

import LaunchIcon from '@mui/icons-material/Launch'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, IconButton, TableCell, TableRow } from '@mui/material'

import { Actions } from '@components/Actions'
import { ProjectAdres } from '@components/Adres'
import ExpandRow from '@components/ExpandRow'
import Label from '@components/Label'
import { MyListButton } from '@components/MyListButton'
import Realisatie from '@components/Realisatie'
import StyledIconLink from '@components/StyledIconLink'
import { formatDate } from '@util/date-util'

import Status from '../../project/components/Status'
import type { Project } from '../../project/types'
import type { Filters } from '../types'

import AansluitObjecten from './AansluitObjecten'
import { DisciplineVerzamelingen } from './DisciplineVerzamelingen'

interface Props {
  row: Project
  onClickMenu: any
  showActions: boolean
  filters: Filters
}

export const Row: FC<Props> = ({ row, onClickMenu, showActions, filters }) => {
  const [expandObject, setExpandObject] = useState(false)
  const [expandDiscipline, setExpandDiscipline] = useState(false)

  const handleObjectenClick = () => {
    setExpandDiscipline(false)
    setExpandObject(!expandObject)
  }

  const handleDisciplinesClick = () => {
    setExpandObject(false)
    setExpandDiscipline(!expandDiscipline)
  }

  const handleMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClickMenu(event.currentTarget, row)
  }

  return (
    <>
      <TableRow key={row.aanvraagID}>
        <TableCell>
          <Box sx={{ pl: '22px' }}>
            <Status project={row} />
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            {row.aanvraagID}
            {row.labels && row.labels.length > 0 && (
              <div>
                {row.labels.map((l) => (
                  <Label text={l} key={l} small />
                ))}
              </div>
            )}
          </Box>
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell>{formatDate(row.aanvraagDatum)}</TableCell>
        <TableCell>
          <Realisatie
            date={row.geplandeUitvoeringsdatum as string}
            type={row.geplandeUitvoeringsdatumType}
            wachtTijd={row.wachtTijd}
          />
        </TableCell>
        <TableCell>{row && <ProjectAdres {...row} />}</TableCell>
        {showActions ? (
          <TableCell colSpan={2}>
            {row.type === 'Aanvraag' && (
              <Actions
                acties={row.acties}
                selectedActie={filters.actie as any}
                selectedActiehouder={filters.actiehouder as any}
              />
            )}
          </TableCell>
        ) : (
          <>
            <TableCell>
              <ExpandRow
                data-testid="chipObjecten"
                label={row.aantalObjecten.toString()}
                expanded={expandObject}
                onDelete={handleObjectenClick}
                onClick={handleObjectenClick}
              />
            </TableCell>
            <TableCell>
              <ExpandRow
                data-testid="chipDisciplines"
                label={row.uniqDisciplines.toString()}
                expanded={expandDiscipline}
                onDelete={handleDisciplinesClick}
                onClick={handleDisciplinesClick}
              />
            </TableCell>
          </>
        )}
        <TableCell>{row.gebiedscodeLabel}</TableCell>
        <TableCell>{row.statusText}</TableCell>
        <TableCell>
          <MyListButton
            active={row.myList}
            type="aansluiting"
            aanvraagID={row.aanvraagID}
          />

          <StyledIconLink
            href={`/aansluiting/project/${row.aanvraagID}`}
            icon={<LaunchIcon />}
            title={`Navigeer naar project ${row.aanvraagID}`}
          />

          <IconButton data-testid="buttonMenu" onClick={handleMenu}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {expandDiscipline ? <DisciplineVerzamelingen row={row} /> : null}
      {expandObject ? <AansluitObjecten project={row} /> : null}
    </>
  )
}
