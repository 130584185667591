import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteIcon from '@mui/icons-material/Delete'
import { TableCell, TableRow } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import { formatDateTime } from '@util/date-util'

import { getUser } from '../../../auth/state'
import type { RootState } from '../../../store'
import type { AssignedBijlage, Bijlage, BijlageLog } from '../types'

import { AssignedBijlageLogStatus } from './AssignedBijlageLogStatus'

type BijlageOrLog = Bijlage | BijlageLog

const sortBijlagenWithLogs = (a: BijlageOrLog, b: BijlageOrLog) => {
  const extractDate = (x: BijlageOrLog) =>
    'created' in x ? x.created.date : x.date

  return extractDate(a) < extractDate(b) ? -1 : 1
}

interface Props {
  assignedBijlage: AssignedBijlage
  bijlagen: Bijlage[]
  onCancel: (bijlage: Bijlage | AssignedBijlage) => void
  onOpen: (bijlage: Bijlage) => void
}

export const BijlagenWithLogRows: FC<Props> = ({
  assignedBijlage,
  bijlagen,
  onCancel,
  onOpen,
}) => {
  const {
    user: { combi, organisatie },
  } = useSelector((state: RootState) => ({
    user: getUser(state),
  }))
  const { assignedTo, log, requiresReview } = assignedBijlage
  const isOpgeleverd = assignedBijlage.status === 'done'
  const assignedToOrganisatie = assignedTo === organisatie

  const bijlagenWithLogs: BijlageOrLog[] = [...bijlagen]

  if (requiresReview) {
    const logs = log.filter(
      (log) =>
        log.status === 'done' || (log.status === 'uploaded' && log.reden),
    )

    bijlagenWithLogs.push(...logs)
    bijlagenWithLogs.sort(sortBijlagenWithLogs)
  }

  return (
    <>
      {bijlagenWithLogs.map((data) => {
        const isBijlage = 'log' in data
        const isLog = !isBijlage

        if (isBijlage) {
          const {
            _id,
            naam,
            created: { organisatie, date },
            status,
          } = data
          const isCancelled = status === 'cancelled'

          return (
            <TableRow
              key={_id}
              sx={{
                backgroundColor: isCancelled ? 'lightgray !important' : '',
              }}
            >
              <TableCell>{`${naam}${
                isCancelled ? ' (geannuleerd)' : ''
              }`}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{formatDateTime(date)}</TableCell>
              <TableCell>{organisatie}</TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ alignSelf: 'right' }}>
                <StyledIconButton
                  title="Bijlage openen"
                  onClick={() => onOpen(data)}
                  icon={<CloudDownloadIcon />}
                />
                {(combi || assignedToOrganisatie) &&
                  !isCancelled &&
                  !isOpgeleverd && (
                    <StyledIconButton
                      title="Bijlage annuleren"
                      icon={<DeleteIcon />}
                      onClick={() => onCancel(data)}
                    />
                  )}
              </TableCell>
            </TableRow>
          )
        }

        if (isLog) {
          const { date, organisatie, reden, status } = data
          const rejected = status === 'uploaded' && reden

          return (
            <TableRow key={`${date}-${organisatie}`}>
              <TableCell>
                {`${assignedBijlage.documentsoort} ${
                  rejected ? ' afgekeurd' : ' goedgekeurd'
                }${reden ? ` - ${reden}` : ''}`}
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <AssignedBijlageLogStatus
                  logEntry={data}
                  assignedBijlage={assignedBijlage}
                />
              </TableCell>
              <TableCell>{formatDateTime(date)}</TableCell>
              <TableCell>{organisatie}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )
        }
      })}
    </>
  )
}
