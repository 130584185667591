import React, { useEffect, useState } from 'react'

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import DirectionsIcon from '@mui/icons-material/Directions'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'

import { useDebounce } from '../../hooks/debounce'

function SuggestIcon({ k }) {
  switch (k) {
    case 'straat':
      return <DirectionsIcon sx={{ pr: '5px' }} />
    case 'plaats':
      return <LocationCityIcon sx={{ pr: '5px' }} />
    case 'postcode':
      return <LocationOnIcon sx={{ pr: '5px' }} />
    default:
      return <DesktopWindowsIcon sx={{ pr: '5px' }} />
  }
}

const delay = 500

export default function FilterAutosuggest({
  search,
  options,
  onFilterChange,
  onSearchChange,
}) {
  const [open, setOpen] = useState(false)
  const debouncedSearch = useDebounce(search, delay)

  useEffect(() => {
    onSearchChange(debouncedSearch)
  }, [debouncedSearch, onSearchChange])

  const handleSearchChange = ({ search, searchOption }: any) => {
    if (searchOption) {
      onFilterChange({ search: searchOption.v, searchOption }, true)
    } else {
      onFilterChange({ search, searchOption: null })
    }
  }

  const handleKeyDown = (event) => {
    const ENTER_KEY = 13

    if (event.keyCode === ENTER_KEY) {
      onFilterChange({ search, searchOption: null }, true, true)
      setOpen(false)
    }
  }

  return (
    <Autocomplete
      datatest-id="filter-autosuggest"
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.v
      }
      filterOptions={(x) => x}
      options={options}
      disableClearable
      autoComplete
      includeInputInList
      freeSolo
      value={search}
      onChange={(_, searchOption) => handleSearchChange({ searchOption })}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          data-testid="filterfield"
          label="Filter overzicht"
          value={search}
          onChange={(event) =>
            handleSearchChange({ search: event.target.value })
          }
          onKeyDown={handleKeyDown}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Grid container alignItems="center" {...props} {...({} as any)}>
            <Grid>
              <SuggestIcon {...option} />
            </Grid>
            <Grid item xs>
              {option.parts.map((part, index) => (
                <span
                  key={index}
                  // eslint-disable-next-line no-magic-numbers
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option.k}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
