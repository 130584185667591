import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material'

import type { NetbeheerderDiscipline } from '@slices/valuelists.types'
import { getNetbeheerderDisciplinesByGebied } from '@slices/valuelistsSlice'

import type { RootState } from '../../../../store'
import { saveEvent } from '../../state'
import type { OptionsDialogData } from '../../types'

const DISCIPLINES_REDENEN = [
  'Disciplines kloppen niet',
  'Plannen aangepast, geen werkzaamheden voor deze partij',
  'Initiatiefnemer niet akkoord met offerte',
  'Partij stapt uit Combi',
]

export interface Props {
  data: OptionsDialogData
  handleClose: () => void
}

export const Disciplines: FC<Props> = ({ data, handleClose }: Props) => {
  const dispatch = useDispatch()
  const { project } = data
  const { netbeheerderDisciplinesByGebied } = useSelector(
    (state: RootState) => ({
      netbeheerderDisciplinesByGebied:
        getNetbeheerderDisciplinesByGebied(state),
    }),
  )
  const [reden, setReden] = useState<string | null>(null)
  const [netbeheerderDisciplines, setNetbeheerderDisciplines] = useState<
    NetbeheerderDiscipline[]
  >([])
  const [netbeheerderDisciplinesOptions, setNetbeheerderDisciplinesOptions] =
    useState<NetbeheerderDiscipline[]>([])
  const [errors, setErrors] = useState<Record<string, boolean>>({})

  const handleSubmitClick = () => {
    if (!reden) {
      setErrors({
        redenError: true,
      })
    } else {
      setErrors({
        redenError: false,
      })

      dispatch(
        saveEvent({
          id: project.aanvraagID,
          data: {
            netbeheerderDisciplines,
            reden,
          },
          type: 'hoofdleiding_disciplines',
          source: 'manual',
        }),
      )
    }
  }

  useEffect(() => {
    setNetbeheerderDisciplines(
      project.disciplineVerzamelingen as NetbeheerderDiscipline[],
    )
  }, [project.disciplineVerzamelingen])

  useEffect(() => {
    const disciplines = netbeheerderDisciplinesByGebied.find(
      ({ gebiedscode }) => project.gebiedscode === gebiedscode,
    )

    setNetbeheerderDisciplinesOptions(
      disciplines?.netbeheerderDisciplines || [],
    )
  }, [project.gebiedscode, netbeheerderDisciplinesByGebied])

  return (
    <>
      <DialogTitle>Wijzigen disciplines</DialogTitle>
      <DialogContent>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            multiple
            options={netbeheerderDisciplinesOptions}
            isOptionEqualToValue={(option, value) => {
              return (
                option.netbeheerderCode === value.netbeheerderCode &&
                option.disciplineCode === value.disciplineCode
              )
            }}
            getOptionLabel={(option) => {
              return `${option.netbeheerderLabel} - ${option.disciplineLabel}`
            }}
            onChange={(_, disc) => setNetbeheerderDisciplines(disc)}
            value={netbeheerderDisciplines}
            renderInput={(params) => {
              return (
                <TextField variant="standard" {...params} label="Disciplines" />
              )
            }}
          />
        </FormControl>
        <FormControl sx={{ width: '100%', mt: 2 }}>
          <Autocomplete
            options={DISCIPLINES_REDENEN}
            onChange={(_, reden) => setReden(reden)}
            value={reden}
            renderInput={(params) => {
              return (
                <TextField
                  variant="standard"
                  {...params}
                  label="Reden"
                  error={errors.redenError}
                />
              )
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={handleSubmitClick}>
          Wijzigen
        </Button>
      </DialogActions>
    </>
  )
}
