import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'
import { endOfDay, startOfDay } from '@util/date-util'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { DashboardTile, Filters, State, Werklijst } from './types'

export const initialState: State = {
  data: {
    kpis: [],
    activiteiten: [],
    bijlagen: [],
  },
  query: {
    filters: {
      gebied: [],
      labels: [],
      organisatie: null,
      netbeheerder: null,
      myList: false,
      startUitvoeringWeekTot: '',
      startUitvoeringWeekVan: '',
      fromDate: null,
      toDate: null,
    },
  },
  selectedTab: 'processtappen',
  status: 'idle',
}

export const createFilters = ({ gebied, labels, myList }: Partial<Filters>) => {
  const filters: IFilter[] = []

  if (gebied && gebied.length > 0)
    filters.push({
      name: 'gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const createFiltersKpi = ({ netbeheerder }: Partial<Filters>) => {
  const filters: IFilter[] = []

  if (netbeheerder)
    filters.push({ name: 'netbeheerders', value: netbeheerder, operator: 'eq' })

  return filters
}

export const createFiltersWerklijst = ({
  organisatie,
  startUitvoeringWeekTot,
  startUitvoeringWeekVan,
  fromDate,
  toDate,
}: Partial<Filters>) => {
  const filters: IFilter[] = []

  if (organisatie)
    filters.push({
      name: 'organisatie',
      value: organisatie.label,
      operator: 'eq',
    })

  filters.push({
    name: 'isActive',
    value: true,
    operator: 'eq',
  })

  if (fromDate) {
    filters.push({
      name: 'dueDate',
      value: startOfDay(fromDate),
      operator: 'gte',
    })
  }

  if (toDate) {
    filters.push({
      name: 'dueDate',
      value: endOfDay(toDate),
      operator: 'lte',
    })
  }

  if (startUitvoeringWeekVan)
    filters.push({
      name: 'searchValues.startUitvoeringWeek',
      value: startUitvoeringWeekVan,
      operator: 'gte',
    })
  if (startUitvoeringWeekTot)
    filters.push({
      name: 'searchValues.startUitvoeringWeek',
      value: startUitvoeringWeekTot,
      operator: 'lte',
    })

  return filters
}

export const fetchKpi = createAsyncThunk<DashboardTile[], { filters: Filters }>(
  'fetch/dashboard/hoofdleiding/kpi',
  async ({ filters }, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/dashboard/hoofdleiding/kpi',
        method: 'POST',
        data: {
          filters: [...createFilters(filters), ...createFiltersKpi(filters)],
        },
      })

      return result
    } catch (error: any) {
      dispatch(apiError("Fout bij ophalen kpi's", error))

      throw error
    }
  },
)

export const fetchWerklijsten = createAsyncThunk<
  {
    acties: Werklijst[]
    activiteiten: Werklijst[]
    bijlagen: Werklijst[]
  },
  { filters: Filters }
>('fetch/dashboard/hoofdleiding/werklijst', async (payload, { dispatch }) => {
  const { filters } = payload

  try {
    const result = await request({
      url: '/rest/dashboard/hoofdleiding/werklijst',
      method: 'POST',
      data: {
        filters: [
          ...createFilters(filters),
          ...createFiltersWerklijst(filters),
        ],
      },
    })

    return result
  } catch (error: any) {
    dispatch(apiError('Fout bij ophalen werklijsten', error))

    throw error
  }
})

const slice = createSlice({
  name: 'hoofdleiding/dashboard',
  initialState,
  reducers: {
    storeSelectedTab: (state, action: { payload: State['selectedTab'] }) => {
      state.selectedTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKpi.pending, (state, action) => {
        state.query = action.meta.arg
      })
      .addCase(fetchKpi.fulfilled, (state, action) => {
        state.data.kpis = action.payload
      })
      .addCase(fetchKpi.rejected, (state) => {
        state.data.kpis = initialState.data.kpis
      })
      .addCase(fetchWerklijsten.pending, (state, action) => {
        state.status = 'loading'
        state.data.bijlagen = initialState.data.bijlagen
        state.data.activiteiten = initialState.data.activiteiten
        state.query.filters = action.meta.arg.filters
      })
      .addCase(fetchWerklijsten.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data.bijlagen = action.payload.bijlagen
        state.data.activiteiten = action.payload.activiteiten
      })
      .addCase(fetchWerklijsten.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
  },
})

export const getQuery = (state: RootState) => state.hoofdleidingDashboard.query
export const getKpis = (state: RootState) =>
  state.hoofdleidingDashboard.data.kpis
export const getSelectedTab = (state: RootState) =>
  state.hoofdleidingDashboard.selectedTab
export const getWerklijsten = (state: RootState) => ({
  activiteiten: state.hoofdleidingDashboard.data.activiteiten,
  bijlagen: state.hoofdleidingDashboard.data.bijlagen,
})

export const { reducer } = slice
export const { storeSelectedTab } = slice.actions
