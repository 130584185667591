import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid } from '@mui/material'

import { DashboardCard } from '../../../aansluiting/dashboard/components/DashboardCard'
import type { RootState } from '../../../store'
import { initialState } from '../../projecten/state'
import { fetchKpi, getKpis } from '../state'
import type { DashboardTile, Filters } from '../types'

export interface Props {
  filters: Filters
  onClickTile: (query: any, url: any) => void
}

export const Processtappen: FC<Props> = ({ filters, onClickTile }) => {
  const dispatch = useDispatch()

  const { kpis } = useSelector((state: RootState) => ({
    kpis: getKpis(state),
  }))

  useEffect(() => {
    dispatch(fetchKpi({ filters }))
  }, [dispatch, filters])

  const onClick = (kpi: DashboardTile) => {
    const { gebied, labels, netbeheerder, myList } = filters

    onClickTile(
      {
        filters: {
          ...initialState.query.filters,
          gebied,
          labels,
          processtap: kpi.statusText,
          netbeheerder,
          myList,
        },
        page: initialState.query.page,
        sort: initialState.query.sort,
      },
      '/hoofdleiding/projecten',
    )
  }

  return (
    <Box sx={{ p: '50px' }}>
      <Grid sx={{ width: '100%' }} container spacing={2}>
        {kpis
          .filter((kpi) => !kpi.code)
          .map((kpi) => (
            <Grid item key={kpi.statusText}>
              <DashboardCard
                onClick={() => onClick(kpi)}
                label={kpi.statusText}
                count={kpi.total}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
