import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AlarmRoundedIcon from '@mui/icons-material/AlarmRounded'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField, Tooltip } from '@mui/material'
import { parseISO } from 'date-fns'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterAutosuggest from '@components/toolbar/FilterAutosuggest'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { FilterMyList } from '@components/toolbar/FilterMyList'
import { getProcesstappenHoofdleiding } from '@slices/statusconfigSlice'
import { getValuelists } from '@slices/valuelistsSlice'

import { getUser } from '../../../auth/state'
import { getContracten } from '../../../contracten/state'
import type { RootState } from '../../../store'
import CreateEditProjectDialog from '../../project/components/dialogs/CreateEditProjectDialog'
import { openProjectDialog } from '../../project/state'
import type { Filters as StateFilters } from '../types'

export interface Props {
  filters: StateFilters
  initialFilters: StateFilters
  onChange: (filters: StateFilters) => void
  onSearchChange: (search: any) => void
  options: any
}

export const Filters = ({
  filters,
  initialFilters,
  onChange,
  onSearchChange,
  options,
}: Props) => {
  const dispatch = useDispatch()
  const {
    processtappen,
    user: { canCreateProject },
    valuelists: {
      gebieden,
      hoofdleiding: { netbeheerders, gemeenten, typeWerkTypes },
      labels: valueListLabels,
    },
    contracten,
  } = useSelector((state: RootState) => ({
    processtappen: getProcesstappenHoofdleiding(state),
    user: getUser(state),
    valuelists: getValuelists(state),
    contracten: getContracten(state),
  }))

  const [localFilters, setLocalFilters] = useState(filters)
  const {
    active,
    netbeheerder,
    gebied,
    processtap,
    labels,
    search,
    fromWeek,
    toWeek,
    aannemer,
    typeWerk,
    gemeente,
  } = localFilters

  const aannemers = [
    ...new Set(contracten.map((contract) => contract.coordAannemer).flat()),
  ]

  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

  const handleClearFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  const handleFilterChange = useCallback(
    (filter, apply?: boolean) => {
      const newFilters = { ...localFilters, ...filter }

      setLocalFilters(newFilters)

      if (apply) {
        onChange(newFilters)
      }
    },
    [localFilters, onChange],
  )

  const onClickCreateProject = () => {
    dispatch(
      openProjectDialog({
        type: 'create',
        data: {},
      }),
    )
  }

  return (
    <>
      <FilterGridContainer>
        <FilterGridItem>
          <FilterGebied
            value={gebied}
            items={gebieden}
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        <FilterGridItem>
          <FilterAutocomplete
            value={processtap}
            items={processtappen}
            label="Processtap"
            filterName="processtap"
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        <FilterGridItem>
          <TextField
            variant="standard"
            value={fromWeek}
            label={'Gepland van'}
            fullWidth
            onChange={(e) => handleFilterChange({ fromWeek: e.target.value })}
            error={fromWeek !== '' && isNaN(parseISO(fromWeek).getTime())}
            placeholder="bijv. 2023-W01"
          />
        </FilterGridItem>

        <FilterGridItem>
          <TextField
            variant="standard"
            value={toWeek}
            label={'Gepland tot'}
            fullWidth
            onChange={(e) => handleFilterChange({ toWeek: e.target.value })}
            error={toWeek !== '' && isNaN(parseISO(toWeek).getTime())}
            placeholder="bijv. 2023-W01"
          />
        </FilterGridItem>

        <FilterGridItem>
          <FilterAutocomplete
            value={netbeheerder}
            items={netbeheerders}
            label="Netbeheerder"
            filterName="netbeheerder"
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        <FilterGridItem>
          <FilterAutocomplete
            value={aannemer}
            items={aannemers}
            label="Uitvoerende Aannemer"
            filterName="aannemer"
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        <FilterGridItem>
          <FilterAutocomplete
            value={typeWerk}
            items={typeWerkTypes}
            label="Type werk"
            filterName="typeWerk"
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        <FilterGridItem>
          <FilterAutocomplete
            value={gemeente}
            items={gemeenten}
            label="Gemeente"
            filterName="gemeente"
            onFilterChange={handleFilterChange}
          />
        </FilterGridItem>

        {valueListLabels.length > 0 && (
          <FilterGridItem>
            <FilterAutocomplete
              items={valueListLabels}
              label="Labels"
              filterName="labels"
              value={labels}
              onFilterChange={handleFilterChange}
              multiple
            />
          </FilterGridItem>
        )}

        <FilterGridItem>
          <FilterAutosuggest
            search={search}
            options={options}
            onFilterChange={handleFilterChange}
            onSearchChange={onSearchChange}
          />
        </FilterGridItem>

        <FilterGridItem
          minColumns="full"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Reset filters">
            <IconButton data-testid="clearbutton" onClick={handleClearFilters}>
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Zoeken">
            <IconButton
              data-testid="filterbutton"
              onClick={() => onChange(localFilters)}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>

          <FilterMyList
            myList={localFilters.myList}
            onFilterChange={handleFilterChange}
          />

          <Tooltip
            title={
              active
                ? 'Klik om ook inactieve projecten te tonen'
                : 'Klik om alleen actieve projecten te tonen'
            }
          >
            <IconButton
              data-testid="activebutton"
              onClick={() => handleFilterChange({ active: !active }, true)}
            >
              {active ? <AlarmRoundedIcon /> : <AlarmOffRoundedIcon />}
            </IconButton>
          </Tooltip>

          {canCreateProject && (
            <Tooltip title="Aanmaken hoofdleiding project">
              <IconButton onClick={onClickCreateProject}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}

          <LocalStorageButtons
            name="hoofdleidingen"
            storable={localFilters}
            onClickLoad={setLocalFilters}
          />
        </FilterGridItem>
      </FilterGridContainer>

      {canCreateProject && <CreateEditProjectDialog />}
    </>
  )
}
