import React, { FC } from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import LaunchIcon from '@mui/icons-material/Launch'
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material'

import Label from '@components/Label'
import { MyListButton } from '@components/MyListButton'
import StyledIconLink from '@components/StyledIconLink'
import { formatDateTime, parseISO, pastDay } from '@util/date-util'

import { AssignedBijlageCaption } from '../../../core/components/AssignedBijlageCaption'
import type { AssignedBijlage } from '../../../entity/bijlagen/types'

export interface Props {
  assignedBijlage: AssignedBijlage
}

export const Row: FC<Props> = ({ assignedBijlage }) => {
  const {
    _id,
    documentsoort,
    assignedTo,
    dueWeek,
    discipline,
    entity: { id: entityId, type: entityType, gebiedscodeLabel },
    completed,
    searchValues,
    myList,
    title,
    labels,
  } = assignedBijlage
  const { organisatie, date: created } = assignedBijlage.created
  const shouldMarkBijlageAsExpired =
    !!dueWeek && pastDay(parseISO(dueWeek)) && !completed ? true : false

  return (
    <TableRow key={_id}>
      <TableCell>
        {shouldMarkBijlageAsExpired && (
          <Tooltip title="De opleverdatum van deze bijlage is verstreken">
            <ErrorIcon sx={{ color: 'red' }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        {`${title ? `Overig: ${title}` : documentsoort} ${discipline || ''}`}
        <AssignedBijlageCaption assignedBijlage={assignedBijlage} />
      </TableCell>
      <TableCell>{dueWeek}</TableCell>
      <TableCell>{formatDateTime(created)}</TableCell>
      <TableCell>{organisatie}</TableCell>
      <TableCell>{assignedTo}</TableCell>
      <TableCell>
        {searchValues?.naam}
        <br />
        <Typography variant="caption">
          <span>#</span>
          {entityId}
        </Typography>
      </TableCell>
      <TableCell>{searchValues?.gemeente}</TableCell>
      <TableCell>
        {gebiedscodeLabel}
        <br />
        {labels && labels?.map((l) => <Label text={l} key={l} small={true} />)}
      </TableCell>
      <TableCell>
        <MyListButton
          type="hoofdleiding"
          disabled={true}
          active={myList}
          aanvraagID={''}
        />

        <StyledIconLink
          icon={<LaunchIcon />}
          title={`Navigeer naar ${entityType} ${entityId}`}
          href={`/hoofdleiding/${entityType}/${entityId}`}
        />
      </TableCell>
    </TableRow>
  )
}
