import React, { FC, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutoComplete from '@components/toolbar/FilterAutocomplete'
import FilterFromToDate from '@components/toolbar/FilterFromToDate'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { FilterMyList } from '@components/toolbar/FilterMyList'
import { getValuelists } from '@slices/valuelistsSlice'
import { parseISO } from '@util/date-util'

import { getUser } from '../../../auth/state'
import type { RootState } from '../../../store'
import { getSelectedTab } from '../state'
import type { Filters as StateFilters } from '../types'

export interface Props {
  filters: StateFilters
  initialFilters: StateFilters
  onChange: (filters: StateFilters) => void
}

export const Filters: FC<Props> = ({ filters, initialFilters, onChange }) => {
  const {
    valuelists: {
      actiehouders,
      gebieden,
      labels: valueListLabels,
      hoofdleiding: { netbeheerders },
    },
    user: { combi },
    selectedTab,
  } = useSelector((state: RootState) => ({
    valuelists: getValuelists(state),
    user: getUser(state),
    selectedTab: getSelectedTab(state),
  }))

  const [localFilters, setLocalFilters] = useState(filters)
  const {
    gebied,
    organisatie,
    labels,
    netbeheerder,
    myList,
    startUitvoeringWeekTot,
    startUitvoeringWeekVan,
    fromDate,
    toDate,
  } = localFilters

  const handleChange = useCallback(
    (filters: Partial<StateFilters>, submit?: boolean) => {
      setLocalFilters({ ...localFilters, ...filters })

      if (submit) {
        onChange({ ...localFilters, ...filters })
      }
    },
    [localFilters, onChange],
  )

  const reset = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          onFilterChange={handleChange}
          value={gebied}
        />
      </FilterGridItem>

      {combi && selectedTab === 'werklijsten' && (
        <FilterGridItem>
          <FilterAutoComplete
            items={actiehouders}
            label="Organisatie"
            filterName="organisatie"
            value={organisatie}
            onFilterChange={handleChange}
            groupBy={(actiehouder) => actiehouder.group}
          />
        </FilterGridItem>
      )}

      {selectedTab === 'processtappen' && (
        <FilterGridItem>
          <FilterAutoComplete
            items={netbeheerders}
            label="Netbeheerder"
            filterName="netbeheerder"
            value={netbeheerder}
            onFilterChange={handleChange}
          />
        </FilterGridItem>
      )}

      {valueListLabels.length > 0 && (
        <FilterGridItem>
          <FilterAutoComplete
            items={valueListLabels}
            label="Labels"
            filterName="labels"
            onFilterChange={handleChange}
            value={labels}
            multiple
          />
        </FilterGridItem>
      )}
      {selectedTab === 'werklijsten' && (
        <>
          <FilterGridItem minColumns="double">
            <FilterFromToDate
              onFilterChange={handleChange}
              label="Opleverdatum"
              fromDate={fromDate}
              toDate={toDate}
            />
          </FilterGridItem>
          <FilterGridItem>
            <TextField
              variant="standard"
              value={startUitvoeringWeekVan}
              label={'Project gepland van'}
              fullWidth
              onChange={(e) =>
                handleChange({ startUitvoeringWeekVan: e.target.value })
              }
              error={
                startUitvoeringWeekVan !== '' &&
                isNaN(parseISO(startUitvoeringWeekVan).getTime())
              }
              placeholder="bijv. 2023-W01"
            />
          </FilterGridItem>

          <FilterGridItem>
            <TextField
              variant="standard"
              value={startUitvoeringWeekTot}
              label={'Project gepland tot'}
              fullWidth
              onChange={(e) =>
                handleChange({ startUitvoeringWeekTot: e.target.value })
              }
              error={
                startUitvoeringWeekTot !== '' &&
                isNaN(parseISO(startUitvoeringWeekTot).getTime())
              }
              placeholder="bijv. 2023-W01"
            />
          </FilterGridItem>
        </>
      )}

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={reset}>
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList myList={myList} onFilterChange={handleChange} />

        <LocalStorageButtons
          name="hoofdleidingDashboard"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
