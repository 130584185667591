import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import GradingIcon from '@mui/icons-material/Grading'
import ListIcon from '@mui/icons-material/List'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material'

import LocalStorageButtons from '@components/LocalStorageButtons'
import FilterAutocomplete from '@components/toolbar/FilterAutocomplete'
import FilterGebied from '@components/toolbar/FilterGebied'
import FilterGridContainer from '@components/toolbar/FilterGridContainer'
import FilterGridItem from '@components/toolbar/FilterGridItem'
import { FilterMyList } from '@components/toolbar/FilterMyList'
import { getProcesstappen } from '@slices/statusconfigSlice'
import { getValuelists } from '@slices/valuelistsSlice'
import { parseISO } from '@util/date-util'
import { documentsoorten } from '@util/hoofdleiding-util'

import { getUser } from '../../../auth/state'
import type { RootState } from '../../../store'
import type { Filters as StateFilters } from '../types'

export interface Props {
  onChange: (filters: StateFilters) => void
  filters: StateFilters
  initialFilters: StateFilters
}

export const Filters: FC<Props> = ({ onChange, filters, initialFilters }) => {
  const [localFilters, setLocalFilters] = useState(filters)
  const {
    gebied,
    documentsoort,
    assignedTo,
    fromWeek,
    toWeek,
    labels,
    review,
    myList,
    startUitvoeringWeekVan,
    startUitvoeringWeekTot,
    gemeente,
    processtatus,
  } = localFilters

  const {
    processtappen,
    valuelists: {
      coordinerenden,
      hoofdleiding: { netbeheerders, gemeenten },
      gebieden,
      labels: valueListLabels,
    },
    user: { combi },
  } = useSelector((state: RootState) => ({
    processtappen: getProcesstappen(state),
    valuelists: getValuelists(state),
    user: getUser(state),
  }))

  const buildOption = (group) => (label) => ({ group, label })
  const assignees = (combi ? [buildOption('Combi')(combi)] : [])
    .concat(netbeheerders.map(buildOption('Netbeheerder')))
    .concat(coordinerenden.map(buildOption('Coordinerende')))

  const handleFilterChange = (filter, apply?: boolean) => {
    const newFilters = {
      ...localFilters,
      ...filter,
    }

    setLocalFilters(newFilters)

    if (apply) onChange(newFilters)
  }

  const resetFilters = () => {
    setLocalFilters(initialFilters)
    onChange(initialFilters)
  }

  return (
    <FilterGridContainer>
      <FilterGridItem>
        <FilterAutocomplete
          items={documentsoorten}
          label="Documentsoort"
          filterName="documentsoort"
          value={documentsoort}
          onFilterChange={handleFilterChange}
          multiple
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterGebied
          items={gebieden}
          value={gebied}
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      {combi && (
        <FilterGridItem>
          <Autocomplete
            options={assignees}
            groupBy={(assignedTo) => assignedTo.group}
            onChange={(_, assignedTo) => handleFilterChange({ assignedTo })}
            getOptionLabel={(assignedTo) => assignedTo.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            value={assignedTo}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{ minWidth: '220px', maxWidth: '350px' }}
                {...params}
                label="Toegewezen aan"
              />
            )}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem>
        <TextField
          variant="standard"
          value={fromWeek}
          label={'Opleverweek van'}
          fullWidth
          onChange={(e) => handleFilterChange({ fromWeek: e.target.value })}
          error={fromWeek !== '' && isNaN(parseISO(fromWeek).getTime())}
          placeholder="bijv. 2023-W01"
        />
      </FilterGridItem>

      <FilterGridItem>
        <TextField
          variant="standard"
          value={toWeek}
          label={'Opleverweek tot'}
          fullWidth
          onChange={(e) => handleFilterChange({ toWeek: e.target.value })}
          error={toWeek !== '' && isNaN(parseISO(toWeek).getTime())}
          placeholder="bijv. 2023-W01"
        />
      </FilterGridItem>

      <FilterGridItem>
        <TextField
          variant="standard"
          value={startUitvoeringWeekVan}
          label={'Project gepland van'}
          fullWidth
          onChange={(e) =>
            handleFilterChange({ startUitvoeringWeekVan: e.target.value })
          }
          error={
            startUitvoeringWeekVan !== '' &&
            isNaN(parseISO(startUitvoeringWeekVan).getTime())
          }
          placeholder="bijv. 2023-W01"
        />
      </FilterGridItem>

      <FilterGridItem>
        <TextField
          variant="standard"
          value={startUitvoeringWeekTot}
          label={'Project gepland tot'}
          fullWidth
          onChange={(e) =>
            handleFilterChange({ startUitvoeringWeekTot: e.target.value })
          }
          error={
            startUitvoeringWeekTot !== '' &&
            isNaN(parseISO(startUitvoeringWeekTot).getTime())
          }
          placeholder="bijv. 2023-W01"
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          value={gemeente}
          items={gemeenten}
          label="Gemeente"
          filterName="gemeente"
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      <FilterGridItem>
        <FilterAutocomplete
          value={processtatus}
          items={processtappen}
          label="Processtap"
          filterName="processtatus"
          onFilterChange={handleFilterChange}
        />
      </FilterGridItem>

      {valueListLabels.length > 0 && (
        <FilterGridItem>
          <FilterAutocomplete
            items={valueListLabels}
            label="Labels"
            filterName="labels"
            value={labels}
            onFilterChange={handleFilterChange}
            multiple
          />
        </FilterGridItem>
      )}

      <FilterGridItem
        minColumns="full"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Tooltip title="Reset filters">
          <IconButton data-testid="clearbutton" onClick={() => resetFilters()}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoeken">
          <IconButton
            data-testid="filterbutton"
            onClick={() => onChange(localFilters)}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>

        <FilterMyList myList={myList} onFilterChange={handleFilterChange} />

        <Tooltip
          title={
            review
              ? 'Klik om alle bijlagen te tonen'
              : 'Klik om alleen te beoordelen bijlagen te tonen'
          }
        >
          <IconButton
            data-testid="activebutton"
            onClick={() => handleFilterChange({ review: !review }, true)}
          >
            {review ? <ListIcon /> : <GradingIcon />}
          </IconButton>
        </Tooltip>

        <LocalStorageButtons
          name="hoofdleidingAssigned_bijlagen"
          storable={localFilters}
          onClickLoad={setLocalFilters}
        />
      </FilterGridItem>
    </FilterGridContainer>
  )
}
