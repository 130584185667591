import React, { FC } from 'react'

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import LockOpen from '@mui/icons-material/LockOpen'
import { AppBar, Button, Toolbar, useScrollTrigger } from '@mui/material'
import { Box } from '@mui/system'

import { login } from '../../gateways/auth0'

import logo from './mijnaansluiting-logo.svg'

export const LoginNavigation: FC = () => {
  const trigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })

  const handleClickLogin = () => login()

  return (
    <AppBar
      position="sticky"
      sx={
        trigger
          ? { background: 'white', elevation: 4 }
          : { background: 'transparent', boxShadow: 'none' }
      }
      id={trigger ? 'elevated' : 'flat'}
    >
      <Toolbar sx={{ color: 'black', backgroundColor: 'transparent' }}>
        <Box sx={{ width: 190 }} component="img" src={logo} />
        <Box sx={{ flex: 1 }}></Box>
        <Button
          href="https://wiki.dsplatform.nl/index.php/Registreren_voor_CAPO"
          target="_blank"
          rel="noreferrer"
          sx={{ color: 'black' }}
          color="secondary"
          startIcon={<AssignmentIndIcon />}
        >
          Registeren voor CAPO
        </Button>
        <Button
          sx={{ color: 'black' }}
          startIcon={<LockOpen />}
          onClick={handleClickLogin}
        >
          Inloggen
        </Button>
      </Toolbar>
    </AppBar>
  )
}
