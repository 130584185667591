import React, { FC } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'

import { createAssignedBijlageForEntity } from '../../state'
import type { BijlageTemplate } from '../../types'

type Option = { group: string; label: string }

interface Props {
  data: any
  onClose: () => void
}

export const Create: FC<Props> = ({ data, onClose }: Props) => {
  const dispatch = useDispatch()

  const disciplines = ['Elektra', 'Gas', 'Water', 'Media', 'Riool']

  const [errors, setErrors] = useState({})
  const [documentsoort, setDocumentsoort] = useState<any>(null)
  const [title, setTitle] = useState('')
  const [discipline, setDiscipline] = useState<string | null>(null)
  const [assignedTo, setAssignedTo] = useState<Option | null>(null)
  const [herplanDiff, setHerplanDiff] = useState('0')

  const {
    entity = {},
    isHoofdleidingProject,
    assignees = [],
    processtatus,
    bijlageTemplates = [],
  } = data as {
    entity: any
    isHoofdleidingProject: boolean
    assignees: any
    processtatus: any
    bijlageTemplates: BijlageTemplate[]
  }

  const { id } = entity

  const isNaUitvoering = ['In uitvoering', 'Oplevering'].includes(processtatus)

  const documentsoorten = bijlageTemplates
    .filter((bt) => bt.processtatus === processtatus)
    .map(({ documentsoort, perDiscipline }) => ({
      code: documentsoort,
      label: documentsoort,
      perDiscipline,
    }))

  const validate = () => {
    const documentSoortError = !documentsoort
    const assignedToError = !assignedTo
    const disciplineError = documentsoort?.perDiscipline && !discipline
    const titleError = documentsoort?.label === 'Overig' && !title

    setErrors({
      documentSoortError,
      assignedToError,
      disciplineError,
      titleError,
    })

    return !(
      documentSoortError ||
      assignedToError ||
      disciplineError ||
      titleError
    )
  }

  const onSubmitClick = () => {
    if (validate()) {
      const bijlageInfo: any = {
        //@ts-ignore - checked in validate
        assignedTo: assignedTo.label,
        processtatus,
        status: 'assigned',
        documentsoort: documentsoort.code,
        title,
      }

      if (discipline) {
        bijlageInfo.discipline = discipline
      }

      if (isHoofdleidingProject && documentsoort.code === 'Overig') {
        if (herplanDiff) {
          bijlageInfo.herplanDiff = isNaUitvoering
            ? herplanDiff // after uitvoering when in phase in uitvoering or oplevering
            : -herplanDiff // before uitvoering date when in other phases
        }

        bijlageInfo.title = title
      }

      const body = {
        entity,
        bijlageInfo,
      }

      dispatch(createAssignedBijlageForEntity(body))
    }
  }

  return (
    <>
      <DialogTitle>
        Aanmaken bijlage voor project {id} (fase {processtatus})
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ pr: 1, pb: 1 }}
          options={documentsoorten}
          onChange={(_, documentsoort) => {
            setDiscipline(null)
            setDocumentsoort(documentsoort)
          }}
          value={documentsoort}
          size={'medium'}
          renderInput={(params) => (
            <TextField
              variant="standard"
              {...params}
              label="Documentsoort"
              error={errors['documentSoortError']}
            />
          )}
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />

        {documentsoort?.label === 'Overig' && (
          <TextField
            sx={{ pr: 1, pb: 1 }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            variant="standard"
            label={`Titel (${title.length}/200)`}
            fullWidth
            error={errors['titleError']}
            inputProps={{ maxLength: 200 }}
          />
        )}

        <Box sx={{ display: 'flex', pb: 1 }}>
          <Autocomplete
            sx={{ width: '50%', pr: 1 }}
            options={assignees}
            groupBy={(assignedTo) => assignedTo.group}
            onChange={(_, assignee) => setAssignedTo(assignee)}
            getOptionLabel={(assignedTo) => assignedTo.label}
            value={assignedTo}
            size={'medium'}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                label="Toegewezen aan"
                error={errors['assignedToError']}
              />
            )}
          />

          {documentsoort?.label === 'Overig' && (
            <TextField
              sx={{ width: '50%' }}
              type="number"
              variant="standard"
              value={herplanDiff}
              label={`Aantal weken ${
                isNaUitvoering ? 'na' : 'voor'
              } start uitvoering`}
              onChange={(e) => setHerplanDiff(e.target.value)}
              id="opleverweek"
            />
          )}

          {documentsoort?.perDiscipline && (
            <Autocomplete
              sx={{ width: '50%', pr: 1 }}
              options={disciplines}
              onChange={(_, d: any) => setDiscipline(d)}
              value={discipline}
              size={'medium'}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  label="Discipline"
                  error={errors['disciplineError']}
                />
              )}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Annuleren
        </Button>
        <Button color="primary" onClick={onSubmitClick}>
          Opslaan
        </Button>
      </DialogActions>
    </>
  )
}
