import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { ValuelistsState } from './valuelists.types'

const initialState: ValuelistsState = {
  gebieden: [],
  netbeheerders: [],
  aannemers: [],
  uitvoerendeAannemers: [],
  types: [],
  coordinerenden: [],
  projectdossier: {
    status: [],
  },
  realisatietypes: [],
  actiehouders: [],
  activiteittypes: [],
  deelnemer: {
    netbeheerders: [],
    aannemers: [],
    disciplines: [],
    versies: [],
    gebieden: [],
  },
  hoofdleiding: {
    gemeenten: [],
    netbeheerders: [],
    netbeheerderDisciplinesByGebied: [],
    typeWerkTypes: [],
  },
  projectmelding: {
    status: [],
  },
  labels: [],
}

export const fetchValuelists = createAsyncThunk(
  'fetch/valuelists',
  async (_, { dispatch }) => {
    try {
      const result = await request({ url: '/rest/valuelist', method: 'GET' })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen valuelists', error))

      throw error
    }
  },
)

export const fetchDeelnemerValuelists = createAsyncThunk(
  'fetch/valuelists/deelnemer',
  async (_, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/valuelist/deelnemer',
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen valuelists', error))

      throw error
    }
  },
)

export const fetchHoofdleidingValuelists = createAsyncThunk(
  'fetch/valuelists/hoofdleiding',
  async (_, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/valuelist/hoofdleiding',
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen valuelists', error))

      throw error
    }
  },
)

export const fetchProjectmeldingValuelists = createAsyncThunk(
  'fetch/valuelists/projectmelding',
  async (_, { dispatch }) => {
    try {
      const result = await request({
        url: '/rest/valuelist/projectmelding',
        method: 'GET',
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen valuelists', error))

      throw error
    }
  },
)

const valuelistSlice = createSlice({
  name: 'valuelists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchValuelists.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(fetchValuelists.rejected, (state) => {
        Object.assign(state, initialState)
      })
      .addCase(fetchDeelnemerValuelists.fulfilled, (state, action) => {
        Object.assign(state.deelnemer, action.payload)
      })
      .addCase(fetchDeelnemerValuelists.rejected, (state) => {
        Object.assign(state.deelnemer, initialState.deelnemer)
      })
      .addCase(fetchHoofdleidingValuelists.fulfilled, (state, action) => {
        Object.assign(state.hoofdleiding, action.payload)
      })
      .addCase(fetchHoofdleidingValuelists.rejected, (state) => {
        Object.assign(state, initialState.hoofdleiding)
      })
      .addCase(fetchProjectmeldingValuelists.fulfilled, (state, action) => {
        Object.assign(state.projectmelding, action.payload)
      })
      .addCase(fetchProjectmeldingValuelists.rejected, (state) => {
        Object.assign(state, initialState.projectmelding)
      })
  },
})

export const getValuelists = (state: RootState) => state.valuelists

export const getGemeenten = (state: RootState) =>
  state.valuelists.hoofdleiding.gemeenten

export const getNetbeheerderDisciplinesByGebied = (state: RootState) =>
  state.valuelists.hoofdleiding.netbeheerderDisciplinesByGebied

export const getTypeWerkTypes = (state: RootState) =>
  state.valuelists.hoofdleiding.typeWerkTypes

export default valuelistSlice.reducer
