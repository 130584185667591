import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { Project } from '../../../aansluiting/project/types'
import type { Entity } from '../../../core/models/Entity'
import { explodeKnownProjects } from '../../../core/util/project-util'
import {
  fetchLinkedProjectsForEntity,
  getLinkedProjectsForEntity,
} from '../state'

export const useLinkedProjects = ({
  entity,
  linkedProjectIds,
}: {
  entity: Entity
  linkedProjectIds?: string[]
}): { linkedProjects: Partial<Project>[] } => {
  const dispatch = useDispatch()
  const projects = useSelector(getLinkedProjectsForEntity)
  const { id, type, projecttype } = entity

  useEffect(() => {
    dispatch(
      fetchLinkedProjectsForEntity({
        entity: { id, type, projecttype },
        linkedProjectIds,
      }),
    )
  }, [dispatch, id, type, projecttype, linkedProjectIds])

  return {
    linkedProjects: explodeKnownProjects(linkedProjectIds, projects),
  }
}
