import React, { FC, PropsWithChildren } from 'react'

import { Grid, Link, Typography } from '@mui/material'

const StyledLink: FC<PropsWithChildren<{ url: string }>> = ({
  url,
  children,
}) => {
  return (
    <Typography sx={{ pt: '5px', pb: 1, ml: 5 }}>
      <Link
        href={url}
        target="_blank"
        rel="noreferrer"
        sx={{
          color: '#2d3f58',
          fontWeight: 500,
          marginTop: '1em',
          marginBottom: '1em',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        {children}
      </Link>
    </Typography>
  )
}

const LinkHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      sx={{
        fontFamily: '"Aller Bold",Helvetica,Arial,sans-serif',
        fontSize: '1.3em',
        color: '#2d3f58',
        fontWeight: 600,
        pb: 2,
      }}
    >
      {children}
    </Typography>
  )
}

export const LinkGrid: FC = () => {
  return (
    <Grid container sx={{ width: '80%', m: 'auto' }} spacing={10}>
      <Grid item xs={6}>
        <LinkHeader>Informatie</LinkHeader>
        <StyledLink url="https://wiki.dsplatform.nl/index.php/CAPO_Handleiding">
          Handleiding
        </StyledLink>
        <StyledLink url="https://wiki.dsplatform.nl/index.php/CAPO#Releases_CAPO">
          Release notes
        </StyledLink>
      </Grid>

      <Grid item xs={6}>
        <LinkHeader>Mijnaansluiting.nl</LinkHeader>
        <StyledLink url="https://mijnaansluiting.nl/over-mijnaansluiting">
          Over Mijnaansluiting.nl
        </StyledLink>
        <StyledLink url="https://wiki.dsplatform.nl/index.php/CAPO_Privacy_Policy">
          Privacybeleid CAPO
        </StyledLink>
        <StyledLink url="https://wiki.dsplatform.nl/index.php/CAPO_Disclaimer">
          Disclaimer CAPO
        </StyledLink>
      </Grid>
    </Grid>
  )
}
