import React, { FC, Fragment, useEffect, useState } from 'react'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LabelIcon from '@mui/icons-material/Label'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { blue, green, orange, purple, red, yellow } from '@mui/material/colors'

import DisciplineIcon from '@components/DisciplineIcon'
import Label from '@components/Label'
import type { Event, EventType } from '@models/Event'
import {
  eventTypeMap,
  generateEventDescription,
  questionIdMap,
} from '@util/log-util'

const SHADE = 100

const avatarStylingMap: Record<EventType, { Icon: FC; background: string }> = {
  ['combiuitvoering']: {
    Icon: AccountTreeIcon,
    background: blue[SHADE],
  },
  ['quickscan']: {
    Icon: AccountTreeIcon,
    background: orange[SHADE],
  },
  ['actief']: {
    Icon: NotificationsIcon,
    background: yellow[SHADE],
  },
  ['product-annulering']: {
    Icon: DoNotDisturbAltIcon,
    background: red[SHADE],
  },
  ['herplanning']: {
    Icon: EventRepeatIcon,
    background: red[SHADE],
  },
  ['herplanning_klant']: {
    Icon: PermContactCalendarIcon,
    background: red[SHADE],
  },
  ['hoofdleiding_aanleggen']: {
    Icon: AddCircleIcon,
    background: green[SHADE],
  },
  ['hoofdleiding_disciplines']: {
    Icon: ControlPointDuplicateIcon,
    background: orange[SHADE],
  },
  ['hoofdleiding_aannemer']: {
    Icon: DataSaverOnIcon,
    background: blue[SHADE],
  },
  ['update_labels']: {
    Icon: LabelIcon,
    background: purple[SHADE],
  },
  ['hoofdleiding_processtap']: {
    Icon: MoveDownIcon,
    background: purple[SHADE],
  },
}

interface Props {
  event: Event
  open: boolean
}

const isCollapsible = (data: Record<string, any>) =>
  [
    'questions',
    'netbeheerderDisciplines',
    'isHoofdleidingAanleggen',
    'labels',
    'reden',
    'disciplines',
  ].some((prop) => prop in data)

export const EventCard: FC<Props> = ({ event, open }) => {
  const [localOpen, setLocalOpen] = useState(open)

  const AvatarIcon = avatarStylingMap[event.type].Icon
  const { title, caption } = generateEventDescription(event)
  const collapsible = isCollapsible(event.data)

  useEffect(() => {
    setLocalOpen(open)
  }, [open])

  return (
    <Card key={event._id} variant="outlined" sx={{ mt: 2 }}>
      <CardHeader
        titleTypographyProps={{
          variant: 'body1',
        }}
        title={title}
        subheaderTypographyProps={{
          variant: 'body2',
        }}
        subheader={caption}
        avatar={
          <Avatar
            sx={{
              color: '#333333',
              background: avatarStylingMap[event.type].background,
            }}
            alt={eventTypeMap[event.type]}
          >
            <AvatarIcon />
          </Avatar>
        }
        action={
          collapsible && (
            <IconButton>
              {localOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          )
        }
        onClick={() => setLocalOpen(!localOpen)}
        sx={{
          cursor: collapsible ? 'pointer' : 'default',

          '&:hover .MuiCardHeader-action': {
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: '50%',
          },
        }}
      />

      {collapsible && (
        <Collapse in={localOpen}>
          <Divider />

          <CardContent>
            {'questions' in event.data && (
              <List sx={{ padding: 0 }}>
                {event.data.questions.map((question, index, arr) => {
                  return (
                    <Fragment key={question.id}>
                      {question.id === 'combiuitvoering' &&
                      question.disciplines ? (
                        question.disciplines.map((d, index, arr) => (
                          <Fragment key={d.discipline}>
                            <ListItem disableGutters sx={{ padding: 0 }}>
                              <ListItemIcon>
                                {d.answer ? (
                                  <CheckCircleIcon color="success" />
                                ) : (
                                  <CancelIcon color="error" />
                                )}
                              </ListItemIcon>

                              <ListItemText>
                                {`${d.discipline} ${
                                  d.answer ? 'wel' : 'niet'
                                } mee in combi`}

                                {'text' in d && d.text && (
                                  <>
                                    <br />
                                    <Typography variant="caption">
                                      {d.text}
                                    </Typography>
                                  </>
                                )}
                              </ListItemText>
                            </ListItem>

                            {index !== arr.length - 1 && (
                              <Divider sx={{ pt: 1, mb: 1 }} />
                            )}
                          </Fragment>
                        ))
                      ) : (
                        <ListItem
                          key={question.id}
                          disableGutters
                          sx={{ padding: 0 }}
                        >
                          <ListItemIcon>
                            {question.answer ? (
                              <CheckCircleIcon color="success" />
                            ) : (
                              <CancelIcon color="error" />
                            )}
                          </ListItemIcon>

                          <ListItemText>
                            {questionIdMap[question.id]}
                          </ListItemText>
                        </ListItem>
                      )}

                      {index !== arr.length - 1 && (
                        <Divider sx={{ pt: 1, mb: 1 }} />
                      )}
                    </Fragment>
                  )
                })}
              </List>
            )}

            {'netbeheerderDisciplines' in event.data && (
              <Box sx={{ mb: 2 }}>
                {event.data.netbeheerderDisciplines.map((nbDisc) => (
                  <DisciplineIcon
                    key={nbDisc.disciplineLabel}
                    disciplineCode={nbDisc.disciplineCode}
                    active={true}
                    toolTip={nbDisc.disciplineLabel}
                  />
                ))}
              </Box>
            )}

            {'isHoofdleidingAanleggen' in event.data && (
              <List sx={{ padding: 0 }}>
                <ListItem disableGutters sx={{ padding: 0 }}>
                  <ListItemIcon>
                    {event.data.isHoofdleidingAanleggen ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText>Hoofdleiding aanleggen</ListItemText>
                </ListItem>
              </List>
            )}

            {'labels' in event.data && (
              <>
                {event.data.labels.length > 0 ? (
                  event.data.labels.map((label) => (
                    <Label key={label} text={label} sx={{ mt: 0 }} />
                  ))
                ) : (
                  <Typography paragraph m={0}>
                    Alle labels verwijderd
                  </Typography>
                )}
              </>
            )}

            {'reden' in event.data && (
              <>
                <Typography paragraph sx={{ fontWeight: 700 }} m={0}>
                  Reden
                </Typography>
                <Typography paragraph m={0}>
                  {event.data.reden || 'Geen reden opgegeven.'}
                </Typography>
              </>
            )}

            {'disciplines' in event.data && (
              <List sx={{ padding: 0 }}>
                {event.data.disciplines.map((d, index, arr) => (
                  <Fragment key={d.discipline}>
                    <ListItem sx={{ padding: 0 }} key={d.discipline}>
                      <ListItemIcon>
                        {d.isCombiUitvoering ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary={`${d.discipline} ${
                          d.isCombiUitvoering ? 'in' : 'uit'
                        } combi`}
                        secondary={'reden' in d ? d.reden : undefined}
                      />
                    </ListItem>

                    {index !== arr.length - 1 && (
                      <Divider sx={{ pt: 1, mb: 1 }} />
                    )}
                  </Fragment>
                ))}
              </List>
            )}
          </CardContent>
        </Collapse>
      )}
    </Card>
  )
}
