import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'
import type { RootState } from '../../store'

import type { EntityProjectenState } from './types'

const initialState: EntityProjectenState = {
  linkedProjects: [],
}

export const fetchLinkedProjectsForEntity = createAsyncThunk(
  'fetch/linked/project',
  async ({ entity, linkedProjectIds }: any, { dispatch }) => {
    const { id, projecttype } = entity
    const isHoofdleiding = projecttype === 'Hoofdleiding'

    const path = isHoofdleiding ? 'aansluiting' : 'hoofdleiding'
    const filters = isHoofdleiding
      ? [{ name: 'aanvraagID', value: linkedProjectIds || [], operator: 'in' }]
      : [{ name: 'linkedProjects', operator: 'eq', value: id }]

    try {
      const linkedProjects = await request({
        url: `/rest/project/${path}/search`,
        method: 'POST',
        data: {
          filters,
        },
      })

      return linkedProjects.items
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van gekoppelde projecten', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'entityProjecten',
  initialState,
  reducers: {
    clearEntityProjecten: (state) => {
      state.linkedProjects = initialState.linkedProjects
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinkedProjectsForEntity.fulfilled, (state, action) => {
        state.linkedProjects = action.payload
      })
      .addCase(fetchLinkedProjectsForEntity.rejected, (state) => {
        state.linkedProjects = initialState.linkedProjects
      })
  },
})

export const getLinkedProjectsForEntity = (state: RootState) =>
  state.entityProjecten.linkedProjects

export const { clearEntityProjecten } = slice.actions
export const { reducer } = slice
