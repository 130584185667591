import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import HandymanIcon from '@mui/icons-material/Handyman'
import LabelIcon from '@mui/icons-material/Label'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import { getValuelists } from '@slices/valuelistsSlice'
import { canChangeProjectForProject } from '@util/project-util'

import { getUser } from '../../../../auth/state'
import type { HoofdleidingProject } from '../../../projecten/types'
import type { OptionsDialogData } from '../../types'

export interface Props {
  project: HoofdleidingProject
  anchorEl?: HTMLElement
  onClose: () => void
  onOpenDialog: (data: OptionsDialogData) => void
}

export const ProjectOptionsMenu: FC<Props> = ({
  project,
  anchorEl,
  onClose,
  onOpenDialog,
}: Props) => {
  const {
    user,
    valuelists: { gebieden },
  } = useSelector((state) => ({
    user: getUser(state),
    valuelists: getValuelists(state),
  }))

  const {
    canViewUpdateLabels,
    canCreateHerplanning,
    canCreateWijzigenAannemer,
    canCreateWijzigenDisciplines,
  } = user

  const { statusText } = project

  const selectableLabels =
    gebieden.find((g) => g.code === project.gebiedscode)?.labels || []

  const allowedStatusTextHerplanning = ['Intake', 'Werkvoorbereiding']
  const canUserHerplanHoofdleiding =
    allowedStatusTextHerplanning.includes(project.statusText) &&
    canCreateHerplanning

  const canChangeAannemer =
    statusText !== 'Geannuleerd' &&
    statusText !== 'Gereed' &&
    canCreateWijzigenAannemer

  const canChangeDisciplines =
    statusText !== 'Geannuleerd' &&
    statusText !== 'Gereed' &&
    canCreateWijzigenDisciplines

  const canUpdateLabels = canViewUpdateLabels && selectableLabels.length > 0

  const noOptionsHoofdleiding =
    !canChangeAannemer &&
    !canChangeDisciplines &&
    !canUpdateLabels &&
    !canUserHerplanHoofdleiding

  const canChangeProject = canChangeProjectForProject({ project, user })

  const handleOptionClick = (type: OptionsDialogData['type']) => {
    onClose()
    onOpenDialog({
      project,
      type,
    })
  }
  const handleLabelsClick = () => {
    onOpenDialog({
      project,
      selectableLabels,
      type: 'labels',
    })
    onClose()
  }

  const handleProcesstapClick = () => {
    onOpenDialog({
      project,
      type: 'hoofdleiding_processtap',
    })
    onClose()
  }

  return (
    <Menu
      id="menu-appbar"
      data-testid="menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {canUserHerplanHoofdleiding && (
        <MenuItem onClick={() => handleOptionClick('herplanning')}>
          <ListItemIcon>
            <EventRepeatIcon />
          </ListItemIcon>
          <ListItemText primary={'Herplannen'} />
        </MenuItem>
      )}
      {canChangeAannemer && (
        <MenuItem onClick={() => handleOptionClick('hoofdleiding_aannemer')}>
          <ListItemIcon>
            <HandymanIcon />
          </ListItemIcon>
          <ListItemText primary={'Wijzig aannemer'} />
        </MenuItem>
      )}
      {canChangeDisciplines && (
        <MenuItem onClick={() => handleOptionClick('hoofdleiding_disciplines')}>
          <ListItemIcon>
            <TransferWithinAStationIcon />
          </ListItemIcon>
          <ListItemText primary={'Wijzig disciplines'} />
        </MenuItem>
      )}

      {canUpdateLabels && (
        <MenuItem onClick={handleLabelsClick}>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary="Wijzig labels" />
        </MenuItem>
      )}

      {canChangeProject && (
        <MenuItem onClick={handleProcesstapClick}>
          <ListItemIcon>
            <MoveDownIcon />
          </ListItemIcon>
          <ListItemText primary="Wijzig processtap" />
        </MenuItem>
      )}

      {noOptionsHoofdleiding && (
        <MenuItem disabled>
          <ListItemText primary="Geen opties beschikbaar" />
        </MenuItem>
      )}
    </Menu>
  )
}
