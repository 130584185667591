import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import AlarmOffRoundedIcon from '@mui/icons-material/AlarmOffRounded'
import AssignmentIcon from '@mui/icons-material/Assignment'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import LabelIcon from '@mui/icons-material/Label'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import { getValuelists } from '@slices/valuelistsSlice'

import { getUser } from '../../../../auth/state'
import type { OptionsDialogData, Project } from '../../types'

export interface Props {
  project: Project
  anchorEl?: HTMLElement
  onClose: () => void
  onNavigateToOpdrachten: (project: Project) => void
  onOpenDialog: (data: OptionsDialogData) => void
}

export const ProjectOptionsMenu: FC<Props> = ({
  project,
  anchorEl,
  onClose,
  onNavigateToOpdrachten,
  onOpenDialog,
}: Props) => {
  const {
    user: {
      deelnemer,
      delegerende,
      canViewCombiUitvoeringMelden,
      canViewQuickScan,
      canViewActiefMelden,
      canViewUpdateLabels,
      canCreateHerplanningKlant,
    },
    valuelists: { gebieden },
  } = useSelector((state) => ({
    user: getUser(state),
    valuelists: getValuelists(state),
  }))

  const selectableLabels =
    gebieden.find((g) => g.code === project.gebiedscode)?.labels || []

  const isActiehouderQuickscan =
    project.type === 'Aanvraag' &&
    project.acties &&
    ((deelnemer && project.acties.quickscan.includes(deelnemer)) ||
      (delegerende && project.acties.quickscan.includes(delegerende)))

  const isContractNetbeheerder =
    project.netbeheerdersContract &&
    (project.netbeheerdersContract.includes(deelnemer as string) ||
      project.netbeheerdersContract.includes(delegerende as string))

  const canUserHerplan =
    project.statusText === 'Intake' && canCreateHerplanningKlant

  const canUpdateLabels = canViewUpdateLabels && selectableLabels.length > 0

  const handleOpdrachtenClick = () => {
    onClose()
    onNavigateToOpdrachten(project)
  }

  const handleOptionClick = (type: OptionsDialogData['type']) => {
    onClose()
    onOpenDialog({
      project,
      type,
    })
  }
  const handleLabelsClick = () => {
    onOpenDialog({
      project,
      selectableLabels,
      type: 'labels',
    })
    onClose()
  }

  return (
    <Menu
      id="menu-appbar"
      data-testid="menu"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={handleOpdrachtenClick}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Opdrachten" />
      </MenuItem>

      {canViewCombiUitvoeringMelden && isContractNetbeheerder && (
        <MenuItem onClick={() => handleOptionClick('combiuitvoering')}>
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Mee in combi" />
        </MenuItem>
      )}

      {canViewQuickScan && isContractNetbeheerder && isActiehouderQuickscan && (
        <MenuItem onClick={() => handleOptionClick('quickscan')}>
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Quickscan" />
        </MenuItem>
      )}

      {canViewActiefMelden && (
        <MenuItem onClick={() => handleOptionClick('actief')}>
          <ListItemIcon>
            <AlarmOffRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary={project.isActive ? 'Deactiveren' : 'Activeren'}
          />
        </MenuItem>
      )}
      {canUserHerplan && (
        <MenuItem onClick={() => handleOptionClick('herplanning_klant')}>
          <ListItemIcon>
            <EventRepeatIcon />
          </ListItemIcon>
          <ListItemText primary={'Herplannen'} />
        </MenuItem>
      )}

      {canUpdateLabels && (
        <MenuItem onClick={handleLabelsClick}>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary="Wijzig labels" />
        </MenuItem>
      )}
    </Menu>
  )
}
