import React, { FC } from 'react'

import { Grid, Typography } from '@mui/material'

import { formatDateTime } from '@util/date-util'

import type { AutoEventConfig, Contract } from '../types'

import { AutoEventConfigsList } from './AutoEventConfigsList'

const NAMES = {
  AN: 'Aannemer',
  COMBI: 'Combi',
  NB: 'Netbeheerder',
}

const combiUitvoeringNamen = (arr: string[]) =>
  arr.map((s) => NAMES[s]).join(', ')

const generateListItems = (items) =>
  items.map((item) => (
    <Grid item xs={12} key={`${item.title}-${item.text}`}>
      <Typography gutterBottom variant="subtitle1">
        <b>{item.title}</b>
      </Typography>
      <Typography variant="body2">{item.text}</Typography>
    </Grid>
  ))

const oudeGebiedenGRONDG = [
  'CG014',
  'CG015',
  'CG016',
  'CG017',
  'CG018',
  'CG019',
  'CG020',
]

const generateScopeItem = (combi: string, gebiedscode: string) => {
  const url =
    'https://wiki.dsplatform.nl/index.php/CAPO_Handleiding#Scopebeperkingen_per_combi'
  const hasScopeBeperkingen =
    combi === 'Structin' || oudeGebiedenGRONDG.includes(gebiedscode)

  return (
    hasScopeBeperkingen && (
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          <b>Scope beperkingen</b>
        </Typography>
        <Typography variant="body2">
          Voor dit gebied gelden scope beperkingen, klik{' '}
          <a href={url} target="blank">
            hier
          </a>{' '}
          om deze te bekijken
        </Typography>
      </Grid>
    )
  )
}

const generateSoloNetbeheerders = (soloNetbeheerders) => {
  return (
    soloNetbeheerders && (
      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          <b>Solo netbeheerders</b>
        </Typography>
        {soloNetbeheerders.map((soloNetbeheerder) => {
          const { netbeheerder, ingangsDatum } = soloNetbeheerder

          return (
            <Typography
              key={netbeheerder}
              variant="body2"
            >{`${netbeheerder} vanaf ${formatDateTime(
              ingangsDatum,
            )}`}</Typography>
          )
        })}
      </Grid>
    )
  )
}

const generateAutoEventConfigs = (autoEventConfigs: AutoEventConfig[]) => {
  if (!autoEventConfigs.length) return null

  const micConfigs = autoEventConfigs.filter(
    (config) => config.isCombiUitvoering,
  )
  const nonMicConfigs = autoEventConfigs.filter(
    (config) => !config.isCombiUitvoering,
  )

  return (
    <>
      {micConfigs.length > 0 && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1">
            <b>Automatisch mee in combi</b>
          </Typography>

          <AutoEventConfigsList configs={micConfigs} />
        </Grid>
      )}

      {nonMicConfigs.length > 0 && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1">
            <b>Automatisch uit combi</b>
          </Typography>

          <AutoEventConfigsList configs={nonMicConfigs} />
        </Grid>
      )}
    </>
  )
}

interface Props {
  contract: Contract
}

export const Row: FC<Props> = ({ contract }) => {
  const {
    combi,
    netbeheerders,
    hoofdleidingNetbeheerders,
    coordAannemer,
    kto,
    ktoPlan,
    ktoAutomatic,
    kcm1,
    gebiedscode,
    gedelegeerden,
    soloNetbeheerders,
    hoofdleiding,
    wijzigingCombiUitvoering,
    autoEventConfigs,
  } = contract

  const hoofdleidingNetbeheerderText =
    hoofdleidingNetbeheerders.length > 0
      ? hoofdleidingNetbeheerders.join(', ')
      : netbeheerders.join(', ')

  const info = [
    { title: 'Combi', text: combi },
    { title: 'Gebiedscode', text: gebiedscode },
    { title: 'Netbeheerders', text: netbeheerders.join(', ') },
    {
      title: 'Netbeheerders voor hoofdleiding projecten',
      text: hoofdleidingNetbeheerderText,
    },
    { title: 'Coordinerende aannemers', text: coordAannemer.join(', ') },
    { title: 'Gedelegeerde netbeheerders', text: gedelegeerden.join(', ') },
  ]

  const additionalInfo = [
    {
      title: 'Ingangsdatum KTO eindmeting',
      text: formatDateTime(kto) || 'Datum nog niet gespecifieerd',
    },
    {
      title: 'Ingangsdatum KTO tussenmeting',
      text: formatDateTime(ktoPlan) || 'Datum nog niet gespecifieerd',
    },
    {
      title: 'Versturen KTOs',
      text: ktoAutomatic ? 'Automatisch' : 'Handmatig',
    },
    {
      title: 'Ingangsdatum KCM1',
      text: formatDateTime(kcm1) || 'Datum nog niet gespecifieerd',
    },
    {
      title: 'Hoofdleiding aanmaken',
      text: hoofdleiding ? 'Ja' : 'Nee',
    },
    {
      title: 'Signalering wijziging  combi-samenstelling',
      text: wijzigingCombiUitvoering
        ? combiUitvoeringNamen(wijzigingCombiUitvoering)
        : 'Nee',
    },
  ]

  return (
    <Grid container spacing={2} key={gebiedscode}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          {generateListItems(info)}
          {generateSoloNetbeheerders(soloNetbeheerders)}
          {generateScopeItem(combi, gebiedscode)}
          {generateAutoEventConfigs(autoEventConfigs)}
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container spacing={2}>
          {generateListItems(additionalInfo)}
        </Grid>
      </Grid>
    </Grid>
  )
}
