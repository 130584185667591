import React, { FC, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import { Box, Toolbar } from '@mui/material'

import StyledIconButton from '@components/StyledIconButton'
import StyledTable from '@components/StyledTable'

import type { AuthenticatedUser } from '../../../auth/types'
import type { AssignedBijlage as AssignedBijlageType, Bijlage } from '../types'

import { AssignedBijlage } from './AssignedBijlage'

const headerCells = [
  { id: 'documentsoort', label: 'Documentsoort', sort: false, fixed: 500 },
  { id: 'assignedTo', label: 'Toegewezen aan', sort: false, fixed: 250 },
  { id: 'dueDate', label: 'Oplevering', sort: false, fixed: 200 },
  { id: 'created.date', label: 'Aangemaakt', fixed: 50 },
  { id: 'created.organisatie', label: 'Aangemaakt door', fixed: 150 },
  { id: 'changed', label: 'Opgeleverd', sort: false, fixed: 150 },
  { id: 'opties', label: 'Opties', sort: false, fixed: 180 },
]

const cancelledFilter = (x) => x.status !== 'cancelled'

interface Props {
  assignedBijlagen: AssignedBijlageType[]
  bijlagen: Bijlage[]
  user: AuthenticatedUser | Record<string, any>
  onOpen: (bijlage: Bijlage) => void
  onUpload: (bijlage: AssignedBijlageType) => void
  onCreate: () => void
  onCancel: (bijlage: Bijlage | AssignedBijlageType) => void
  onOpleveren: (bijlage: AssignedBijlageType) => void
  onReview: (bijlage: AssignedBijlageType) => void
  showCreateAssignedBijlage: boolean
  showCancelAssignedBijlage: boolean
}

export const AssignedBijlagen: FC<Props> = ({
  assignedBijlagen,
  bijlagen,
  user,
  onOpen,
  onUpload,
  onCreate,
  onCancel,
  showCreateAssignedBijlage,
  showCancelAssignedBijlage,
  onOpleveren,
  onReview,
}) => {
  const [showCancelled, setShowCancelled] = useState(false)

  return (
    <>
      <Toolbar
        sx={{
          justifyContent: 'flex-end',
          backgroundColor: '#F7F7F7',
          borderBottom: '1px solid #E0E0E0',
        }}
      >
        <Box sx={{ p: 1, textAlign: 'right' }}>
          {showCreateAssignedBijlage && (
            <StyledIconButton
              title="Bijlage toevoegen"
              icon={<AddIcon />}
              onClick={onCreate}
            />
          )}
          <StyledIconButton
            title="Laat ook geannuleerde bijlagen zien"
            icon={
              showCancelled ? <LibraryBooksIcon /> : <LibraryAddCheckIcon />
            }
            onClick={() => setShowCancelled(!showCancelled)}
          />
        </Box>
      </Toolbar>
      <StyledTable variant="details" headerCells={headerCells} {...({} as any)}>
        {assignedBijlagen &&
          assignedBijlagen
            .filter(showCancelled ? () => true : cancelledFilter)
            .map((assignedBijlage) => {
              return (
                <AssignedBijlage
                  key={assignedBijlage._id}
                  assignedBijlage={assignedBijlage}
                  bijlagen={bijlagen
                    .filter(
                      (bijlage) => bijlage.parentId === assignedBijlage._id,
                    )
                    .filter(showCancelled ? () => true : cancelledFilter)}
                  user={user}
                  onOpen={onOpen}
                  onUpload={onUpload}
                  onCancel={onCancel}
                  onOpleveren={onOpleveren}
                  onReview={onReview}
                  showCancelAssignedBijlage={showCancelAssignedBijlage}
                />
              )
            })}
      </StyledTable>
    </>
  )
}
