import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import type { IFilter } from '@models/Filter'

import type { RootState } from '../../../src/store'
import { apiError } from '../../alerts/state'
import { request } from '../../gateways/api'

import type { State } from './types'

export const initialState: State = {
  data: { count: 0, skip: 0, limit: 0, items: [] },
  query: {
    filters: {
      gebied: [],
      documentsoort: [],
      assignedTo: [],
      fromWeek: '',
      toWeek: '',
      labels: [],
      review: false,
      myList: false,
      startUitvoeringWeekTot: '',
      startUitvoeringWeekVan: '',
      gemeente: '',
      processtatus: '',
    },
    sort: { sortBy: 'created.date', sortDirection: 'desc' },
    page: { skip: 0, limit: 25 },
  },
}

export const createFilters = ({
  gebied,
  documentsoort,
  assignedTo,
  toWeek,
  fromWeek,
  labels,
  review,
  myList,
  startUitvoeringWeekVan,
  startUitvoeringWeekTot,
  gemeente,
  processtatus,
}) => {
  const filters: IFilter[] = []

  if (documentsoort && documentsoort.length > 0)
    filters.push({
      name: 'documentsoort',
      value: documentsoort,
      operator: 'in',
    })
  if (gebied && gebied.length > 0)
    filters.push({
      name: 'entity.gebiedscode',
      value: gebied.map(({ code }) => code),
      operator: 'in',
    })
  if (assignedTo && assignedTo.length > 0)
    filters.push({
      name: 'assignedTo',
      value: assignedTo.map(({ label }) => label),
      operator: 'in',
    })
  if (fromWeek)
    filters.push({ name: 'dueWeek', value: fromWeek, operator: 'gte' })
  if (toWeek) filters.push({ name: 'dueWeek', value: toWeek, operator: 'lte' })

  if (startUitvoeringWeekVan)
    filters.push({
      name: 'searchValues.startUitvoeringWeek',
      value: startUitvoeringWeekVan,
      operator: 'gte',
    })
  if (startUitvoeringWeekTot)
    filters.push({
      name: 'searchValues.startUitvoeringWeek',
      value: startUitvoeringWeekTot,
      operator: 'lte',
    })
  if (gemeente)
    filters.push({
      name: 'searchValues.gemeente',
      value: gemeente,
      operator: 'eq',
    })
  if (processtatus) {
    filters.push({
      name: 'processtatus',
      value: processtatus,
      operator: 'eq',
    })
    filters.push({
      name: 'searchValues.statusText',
      value: processtatus,
      operator: 'eq',
    })
  }

  const status = review ? ['review'] : ['assigned', 'uploaded', 'review']
  filters.push({
    name: 'status',
    value: status,
    operator: 'in',
  })

  filters.push({
    name: 'isActive',
    value: true,
    operator: 'eq',
  })

  if (labels && labels.length > 0)
    filters.push({
      name: 'labels',
      value: labels.map(({ code }) => code),
      operator: 'in',
    })

  filters.push({
    name: 'entity.projecttype',
    value: ['Hoofdleiding'],
    operator: 'in',
  })

  if (myList) {
    filters.push({ name: 'my-list', value: true, operator: 'eq' })
  }

  return filters
}

export const fetchAssignedBijlagen = createAsyncThunk(
  'fetch/hoofdleiding/assigned/bijlagen',
  async (payload: any, { dispatch }) => {
    const { filters, page, sort } = payload

    try {
      const result = await request({
        url: '/rest/bijlageassigned/search',
        method: 'POST',
        data: { filters: createFilters(filters), ...page, ...sort },
      })

      return result
    } catch (error: any) {
      dispatch(apiError('Fout bij ophalen van bijlagen', error))

      throw error
    }
  },
)

const slice = createSlice({
  name: 'hoofdleiding/assignedBijlagen',
  initialState,
  reducers: {
    storeQuery: (state, action) => {
      state.query = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedBijlagen.pending, (state, action) => {
        state.status = 'loading'
        state.data = initialState.data
        state.query = action.meta.arg
      })
      .addCase(fetchAssignedBijlagen.fulfilled, (state, action) => {
        state.status = 'idle'
        state.data = action.payload
      })
      .addCase(fetchAssignedBijlagen.rejected, (state) => {
        state.status = 'error'
        state.data = initialState.data
      })
  },
})

export const getAssignedBijlagen = (state: RootState) => ({
  data: state.hoofdleidingAssignedBijlagen.data,
  isLoading: state.hoofdleidingAssignedBijlagen.status === 'loading',
  query: state.hoofdleidingAssignedBijlagen.query,
})

export const { reducer } = slice
export const { storeQuery } = slice.actions
